(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/update.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/update.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  saveImageBase64,
  updateRetailer,
  updateGroup,
  updateGroupName
} = svs.components.marketplaceRetailAdminData.editRetailer.services;
const saveImageTask = (groupId, _ref) => {
  let {
    imageFile,
    imageCrop
  } = _ref;
  return callback => {
    if (!(imageFile && imageCrop)) {
      return callback(null, undefined);
    }
    saveImageBase64(groupId, imageFile, imageCrop, callback);
  };
};
const updateRetailerTask = data => callback => {
  updateRetailer(data, callback);
};
const updateGroupTask = (groupId, _ref2, publish) => {
  let {
    name,
    status
  } = _ref2;
  return callback => {
    if (publish) {
      return updateGroup(groupId, name, status, callback);
    }
    updateGroupName(groupId, name, callback);
  };
};
const updateTask = (groupId, data, publish, hasUnpublishPerm) => (response, callback) => {
  const imagePath = response ? response.path : undefined;
  const coverImage = imagePath ? {
    path: imagePath
  } : data.coverImage;
  const tasks = [updateRetailerTask(_objectSpread(_objectSpread({}, data), {}, {
    coverImage
  }))];
  if (hasUnpublishPerm) {
    tasks.push(updateGroupTask(groupId, data, publish));
  }
  async.parallel(tasks, callback);
};
const update = (groupId, data, publish, hasUnpublishPerm, callback) => {
  async.waterfall([saveImageTask(groupId, data), updateTask(groupId, data, publish, hasUnpublishPerm)], (err, result) => {
    if (err) {
      return callback(err);
    }
    return callback(null, result);
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.update', update);

 })(window);