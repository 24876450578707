(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/edit-retailer-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/edit-retailer-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  PUBLISHED,
  UNPUBLISHED
} = svs.components.marketplaceData.teams.constants.groupStatus;
const {
  editorToJson
} = svs.components.marketplaceRetailAdmin.richText.helpers;
const CHANGE_NAME = 'EDIT_RETAIL/CHANGE_NAME';
const changeName = name => ({
  type: CHANGE_NAME,
  name
});
const CHANGE_DESCRIPTION = 'EDIT_RETAIL/CHANGE_DESCRIPTION';
const changeDescription = description => ({
  type: CHANGE_DESCRIPTION,
  description
});
const CHANGE_EMAIL = 'EDIT_RETAIL/CHANGE_EMAIL';
const changeEmail = email => ({
  type: CHANGE_EMAIL,
  email
});
const CHANGE_CITY = 'EDIT_RETAIL/CHANGE_CITY';
const changeCity = city => ({
  type: CHANGE_CITY,
  city
});
const CHANGE_ADDRESS = 'EDIT_RETAIL/CHANGE_ADDRESS';
const changeAddress = address => ({
  type: CHANGE_ADDRESS,
  address
});
const CHANGE_WEBSITE = 'EDIT_RETAIL/CHANGE_WEBSITE';
const changeWebsite = website => ({
  type: CHANGE_WEBSITE,
  website
});
const CHANGE_PHONE = 'EDIT_RETAIL/CHANGE_PHONE';
const changePhone = phone => ({
  type: CHANGE_PHONE,
  phone: phone.replace(/[^0-9]/g, '')
});
const CHANGE_IMAGE_FILE = 'EDIT_RETAIL/CHANGE_IMAGE_FILE';
const changeImageFile = imageFile => ({
  type: CHANGE_IMAGE_FILE,
  imageFile
});
const CHANGE_IMAGE_CROP = 'EDIT_RETAIL/CHANGE_IMAGE_CROP';
const changeImageCrop = imageCrop => ({
  type: CHANGE_IMAGE_CROP,
  imageCrop
});
const START_EDIT = 'EDIT_RETAIL/START_EDIT';
const startEdit = groupId => (dispatch, getState) => {
  const retailer = getState().Teams.data.find(retailer => retailer.id === groupId);
  if (getState().EditRetailer.isEditing) {
    return;
  }
  if (retailer) {
    dispatch({
      type: START_EDIT,
      retailer: {
        name: retailer.name,
        email: retailer.email,
        website: retailer.website,
        phone: retailer.phone,
        city: retailer.city,
        address: retailer.address,
        coverImage: retailer.coverImage,
        imageFile: undefined,
        imageCrop: {},
        description: retailer.description
      }
    });
  }
};
const STOP_EDIT = 'EDIT_RETAIL/STOP_EDIT';
const stopEdit = () => ({
  type: STOP_EDIT
});
const {
  services
} = svs.components.marketplaceRetailAdminData.editRetailer;
const {
  dialogWrapper
} = svs.components.marketplaceData;
const EDIT_RETAILER_EDIT_INIT = 'EDIT_RETAIL/EDIT_INIT';
const EDIT_RETAILER_EDIT_SUCCESS = 'EDIT_RETAIL/EDIT_SUCCESS';
const EDIT_RETAILER_EDIT_FAILED = 'EDIT_RETAIL/EDIT_FAILED';
const editRetailer = (groupId, publish, hasUnpublishPerm) => (dispatch, getState) => {
  dispatch({
    type: EDIT_RETAILER_EDIT_INIT,
    groupId
  });
  const {
    description,
    name,
    email,
    phone,
    website,
    city,
    address,
    coverImage,
    imageFile,
    imageCrop
  } = getState().EditRetailer;
  const data = {
    name,
    email,
    phone,
    website,
    address,
    city,
    coverImage,
    imageFile,
    imageCrop,
    description: editorToJson(description),
    status: publish ? PUBLISHED : UNPUBLISHED
  };
  services.update(groupId, data, publish, hasUnpublishPerm, error => {
    if (error) {
      if (error.code === 403) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Du verkar sakna behörighet för att göra detta, du måste ha roll Admin eller högre.',
          type: 'error'
        }));
      } else {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Något gick fel. Försök igen om en stund eller kontakta kundservice.',
          type: 'error'
        }));
      }
      dispatch({
        type: EDIT_RETAILER_EDIT_FAILED,
        groupId,
        error
      });
    } else {
      let message = '';
      if (publish === true) {
        message = 'Ombudet har sparats och publicerats.';
      } else {
        message = 'Ombudet har sparats';
      }
      dispatch(dialogWrapper.actions.showDialog({
        title: message,
        icon: 'checkmark'
      }));
      dispatch(_objectSpread(_objectSpread({
        type: EDIT_RETAILER_EDIT_SUCCESS,
        groupId
      }, typeof data.status !== 'undefined' ? {
        isPublished: publish
      } : {}), {}, {
        isDirty: false
      }));
    }
  });
};
const unpublishRetailer = groupId => (dispatch, getState) => {
  dispatch(dialogWrapper.actions.showDialog({
    title: 'Vill du avpublicera dig från Spela tillsammans ombud?',
    text: 'Avpublicering döljer dig från sökningar i Spela tillsammans.',
    buttons: [{
      text: 'Avbryt',
      classes: {
        inverted: true
      }
    }, {
      text: 'Avpublicera',
      classes: {
        focus: true
      },
      callback: () => {
        dispatch({
          type: EDIT_RETAILER_EDIT_INIT,
          groupId
        });
        const {
          name
        } = getState().EditRetailer;
        services.unpublishGroup(groupId, name, error => {
          if (error) {
            dispatch(dialogWrapper.actions.showDialog({
              title: 'Något gick fel. Försök igen om en stund eller kontakta kundservice.',
              type: 'error'
            }));
            dispatch({
              type: EDIT_RETAILER_EDIT_FAILED,
              groupId,
              error
            });
          } else {
            dispatch(_objectSpread({
              type: EDIT_RETAILER_EDIT_SUCCESS,
              groupId
            }, {
              isPublished: false
            }));
          }
        });
      }
    }],
    icon: 'remove-sign'
  }));
};
const NOTIFY_TEAM_MEMBERS_INIT = 'EDIT_RETAIL/NOTIFY_TEAM_MEMBERS_INIT';
const NOTIFY_TEAM_MEMBERS_SUCCESS = 'EDIT_RETAIL/NOTIFY_TEAM_MEMBERS_SUCCESS';
const NOTIFY_TEAM_MEMBERS_FAILED = 'EDIT_RETAIL/NOTIFY_TEAM_MEMBERS_FAILED';
const NOTIFY_TEAM_MEMBERS_CLEAR = 'EDIT_RETAIL/NOTIFY_TEAM_MEMBERS_CLEAR';
const notifyTeamMembers = (message, groupId) => dispatch => {
  dispatch({
    type: NOTIFY_TEAM_MEMBERS_INIT,
    message,
    groupId
  });
  services.notifyTeamMembers(message, error => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Meddelandet kunde inte skickas. Försök igen om en stund eller kontakta kundservice.',
        type: 'error'
      }));
      dispatch({
        type: NOTIFY_TEAM_MEMBERS_FAILED,
        groupId,
        error
      });
    } else {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Meddelandet har skickats',
        icon: 'checkmark'
      }));
      dispatch({
        type: NOTIFY_TEAM_MEMBERS_SUCCESS,
        message,
        groupId
      });
    }
  });
};
const clearNotifyTeamMembers = () => dispatch => {
  dispatch({
    type: NOTIFY_TEAM_MEMBERS_CLEAR
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.actions', {
  CHANGE_NAME,
  changeName,
  CHANGE_EMAIL,
  changeEmail,
  CHANGE_CITY,
  changeCity,
  CHANGE_DESCRIPTION,
  changeDescription,
  CHANGE_ADDRESS,
  changeAddress,
  CHANGE_WEBSITE,
  changeWebsite,
  CHANGE_PHONE,
  changePhone,
  CHANGE_IMAGE_FILE,
  changeImageFile,
  CHANGE_IMAGE_CROP,
  changeImageCrop,
  START_EDIT,
  startEdit,
  STOP_EDIT,
  stopEdit,
  EDIT_RETAILER_EDIT_INIT,
  EDIT_RETAILER_EDIT_SUCCESS,
  EDIT_RETAILER_EDIT_FAILED,
  editRetailer,
  unpublishRetailer,
  NOTIFY_TEAM_MEMBERS_INIT,
  NOTIFY_TEAM_MEMBERS_SUCCESS,
  NOTIFY_TEAM_MEMBERS_FAILED,
  NOTIFY_TEAM_MEMBERS_CLEAR,
  notifyTeamMembers,
  clearNotifyTeamMembers
});

 })(window);