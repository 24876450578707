(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/update-group.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/update-group.js');
"use strict";


const {
  UNPUBLISHED
} = svs.components.marketplaceData.teams.constants.groupStatus;
const {
  jupiter
} = svs.core;
const updateGroup = (groupId, name, status, callback) => {
  if (!groupId || !name || !status) {
    return callback(new Error("Mandatory parameters, missing or invalid value, groupId: ".concat(groupId, ", name: ").concat(name, ", status: ").concat(status)));
  }
  const path = "/retailer/1/marketplace/groups/".concat(groupId);
  jupiter.put({
    path,
    data: {
      name,
      status
    }
  }, response => {
    callback(undefined, response);
  }, error => {
    callback(error);
  });
};
const updateGroupName = (groupId, name, callback) => {
  if (!groupId || !name) {
    return callback(new Error("Mandatory parameters, missing or invalid value, groupId: ".concat(groupId, ", name: ").concat(name, ",")));
  }
  const path = "/retailer/1/marketplace/groups/".concat(groupId);
  jupiter.put({
    path,
    data: {
      name
    }
  }, response => {
    callback(undefined, response);
  }, error => {
    callback(error);
  });
};
const unpublishGroup = (groupId, name, callback) => updateGroup(groupId, name, UNPUBLISHED, callback);
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.updateGroup', updateGroup);
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.updateGroupName', updateGroupName);
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.unpublishGroup', unpublishGroup);

 })(window);