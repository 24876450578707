(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/save-image.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/save-image.js');
"use strict";


const saveImage = (groupId, formData, _ref, callback) => {
  let {
    left: x,
    top: y,
    width: w,
    height: h
  } = _ref;
  const path = "/aruba/v1/mp/".concat(groupId, "/coverImage?cropX=").concat(x, "&cropY=").concat(y, "&cropWidth=").concat(w, "&cropHeight=").concat(h);
  svs.core.jupiter.post({
    path,
    data: formData
  }, response => {
    callback(undefined, response);
  }, error => {
    callback(error);
  });
};
const saveImageBase64 = (groupId, file, _ref2, callback) => {
  let {
    left: x,
    top: y,
    width: w,
    height: h
  } = _ref2;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64Image = reader.result.split('base64,')[1];
    const path = "/aruba/v1/mp/".concat(groupId, "/coverImage/base64?cropX=").concat(x, "&cropY=").concat(y, "&cropWidth=").concat(w, "&cropHeight=").concat(h);
    svs.core.jupiter.post({
      path,
      data: {
        image: base64Image
      }
    }, response => {
      callback(undefined, response);
    }, error => {
      callback(error);
    });
  };
  reader.onerror = error => {
    callback(error);
  };
};
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.saveImage', saveImage);
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.saveImageBase64', saveImageBase64);

 })(window);