(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/notify-team-members.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/notify-team-members.js');
"use strict";


const notifyTeamMembers = (message, callback) => {
  const path = "/retailer/1/marketplace/notify/group";
  svs.core.jupiter.put({
    path,
    data: {
      message,
      type: 'Generic'
    }
  }, response => {
    callback(undefined, response);
  }, error => {
    callback(error);
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.notifyTeamMembers', notifyTeamMembers);

 })(window);