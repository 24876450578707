(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/update-retailer.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/edit-retailer/assets/javascripts/services/update-retailer.js');
"use strict";


const updateRetailer = (data, callback) => {
  const path = "/retailer/1/marketplace/groups";
  const retailerData = mapRetailerData(data);
  svs.core.jupiter.put({
    path,
    data: retailerData
  }, response => {
    callback(undefined, response);
  }, error => {
    callback(error.responseJSON.error);
  });
};
function mapRetailerData(data) {
  return {
    description: data.description || '',
    address: data.address || '',
    city: data.city || '',
    email: data.email || '',
    imageId: (data.coverImage ? data.coverImage.path : '') || '',
    phone: data.phone || '',
    website: data.website || ''
  };
}
setGlobal('svs.components.marketplaceRetailAdminData.editRetailer.services.updateRetailer', updateRetailer);

 })(window);